
<template>
  <div>
    <div class="text-center my-4">
      <h5>Información de la Grantía</h5>
    </div>

    <div class="container-fluid">

      <div class="row justify-content-center">
        <div class="col-sm-3 form-group">
          <label for="nombre" class="form-label">Tipo de garantía jurídica</label>
          <input v-model="data.tipo_garantia" type="text" name="tipo_garantia" id="tipo_garantia" class="form-control">
        </div>
        <div class="col-sm-3 form-group">
          <label for="nombre" class="form-label">Tipo de Inmueble</label>
          <input v-model="data.tipo_inmueble" type="text" name="tipo_inmueble" id="tipo_inmueble" class="form-control">
        </div>
        <div class="col-sm-3 form-group">
          <label for="nombre" class="form-label">Alcaldía/Municipio</label>
          <input v-model="data.municipio" type="text" name="municipio" id="municipio" class="form-control">
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-sm-3 form-group">
          <label for="nombre" class="form-label">Colonia</label>
          <input v-model="data.colonia" type="number" name="colonia" id="colonia" class="form-control">
        </div>
        <div class="col-sm-4 form-group">
          <label for="nombre" class="form-label">Domicilio</label>
          <input v-model="data.domicilio" type="number" name="domicilio" id="domicilio" class="form-control">
        </div>
        <div class="col-sm-2 form-group">
          <label for="nombre" class="form-label">C.P.</label>
          <input v-model="data.cp" type="number" name="cp" id="cp" class="form-control">
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-sm-6 form-group">
          <label for="nombre" class="form-label">Inmueble/Ubicación</label>
          <input v-model="data.ubicacion" type="text" name="ubicacion" id="ubicacion" class="form-control">
        </div>

        <div class="col-sm-3 form-group">
          <label for="nombre" class="form-label">Valor de la garantía</label>
          <input v-model="data.valor_garantia" type="number" name="valor_garantia" id="valor_garantia" class="form-control">
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "Garantia"
  ,components: {}
  ,data(){
    return {
      data:{
        tipo_garantia:null
        ,tipo_inmueble:null
        ,alcaldia:null
        ,colonia:null
        ,domicilio:null
        ,cp:null
        ,ubicacion:null
        ,valor_garantia:null
      }
    }
  }
  ,mounted() {
  }
  ,computed:{}
  ,methods:{}
}
</script>

<style scoped lang="scss">

</style>
